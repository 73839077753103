import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: (()=>import('../views/layout/index.vue'))
  },
  {
    path: '/platfrom',
    name: 'platfrom',
    meta:{
      title:"新聚互动-平台优势"
    },
    component: (()=>import('../views/PlatForm'))
  },
  {
    path: '/adtype',
    name: 'adtype',
    meta:{
      title:"新聚互动-广告类型"
    },
    component: (()=>import('../views/AdType'))
  },
  // {
  //   path: '/marketingcase',
  //   name: 'marketingcase',
  //   meta:{
  //     title:"新聚互动-营销案例"
  //   },
  //   component: (()=>import('../views/MarketingCases'))
  // },
  {
    path: '/more',
    name: 'more',
    meta:{
      title:"关于我们"
    },
    component: (()=>import('../views/More'))
  },  {
    path: '/404',
    name: '404',
    component: (()=>import('../views/404'))
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
