<template>
  <div id="app">
    <div class="nav">
      <div class="nav-left">
        <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%9B%BE%E6%A0%87%2Blogo-02.png" alt="">
      </div>
      <div class="nav-right" @click="changeRouter">
        <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E8%8F%9C%E5%8D%95.png" alt="">
      </div>
    </div>
    <div class="zhe animate__animated animate__fadeInRight" v-show="isShow">
      <div class="zhe-cha">
        <h1 @click="close">×</h1>
      </div>
      <div class="menu-aside">
        <router-link :class="isRouter == 0 ? 'activeRouter' : ''" @click.native="isRouter = 0" to="/">首页</router-link>
        <router-link :class="isRouter == 1 ? 'activeRouter' : ''" @click.native="isRouter = 1" to="/platfrom">平台优势</router-link>
        <router-link :class="isRouter == 2 ? 'activeRouter' : ''" @click.native="isRouter = 2" to="/adtype">广告类型</router-link>
        <!-- <router-link :class="isRouter == 3 ? 'activeRouter' : ''" @click.native="isRouter = 3"
          to="/marketingcase">营销案例</router-link> -->
        <router-link :class="isRouter == 4 ? 'activeRouter' : ''" @click.native="isRouter = 4" to="/more">关于我们</router-link>
      </div>

      <!-- <el-menu class="menu-aside">
      <el-submenu index="1">
        <template slot="title"><i class="el-icon-message"></i>
          <router-link to="/">首页</router-link>
        </template>
        <el-menu-item-group>
          <el-menu-item index="1-1">选项1</el-menu-item>
          <el-menu-item index="1-2">选项2</el-menu-item>
        </el-menu-item-group>
          <el-menu-item index="1-3">选项3</el-menu-item>
        <el-submenu index="1-4">
          <template slot="title">选项4</template>
          <el-menu-item index="1-4-1">选项4-1</el-menu-item>
        </el-submenu>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title"><i class="el-icon-setting"></i>
          <router-link to="/platfrom">平台优势</router-link>
        </template>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title"><i class="el-icon-menu"></i>
          <router-link to="/adtype">广告类型</router-link>
        </template>
      </el-submenu>
      <el-submenu index="4">
        <templat e slot="title"><i class="el-icon-setting"></i>
          <router-link to="/marketingcase">营销案例</router-link>
        </template>
      </el-submenu>
      <el-submenu index="5">
        <template  slot="title"><i class="el-icon-setting"></i>
          <router-link to="/more">关于我们</router-link>
        </template>
      </el-submenu>
    </el-menu> -->

    </div>
    <router-view />
    <!-- 第六部分 -->
    <div class="part-six ">
      <div class="part-six-text">
        <p class="part-six-p1">联系我们：</p>
        <p class="part-six-p2">投放联系：13071811937（张经理）</p>
        <p class="part-six-p3">通过OPPO营销获取更多客户</p>
        <p class="part-six-p4"><a href="http://beian.miit.gov.cn/">浙ICP备2023011741号</a></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false,
      isRouter: 0
    }
  },
  methods: {
    changeRouter() {
      console.log("1111");
      this.isShow = !this.isShow
    },
    close() {
      this.isShow = !this.isShow
    },

  }

}
</script>
<style lang="scss">
.part-six {
  width: 100vw;
  height: 40vw;
  background-color: #1a1a1a;
  color: #fff;
  text-align: left;
  padding-top: 8vw;

  .part-six-text {
    width: 60vw;
    height: 40vw;
    line-height: 8vw;
    margin-left: 16vw;
    font-size: 16px;

    .part-six-p4 {
      text-align: center;

      a {
        margin-top: 5vw;
        text-decoration: none;
        color: #fff;
      }
    }
  }
}

.activeRouter {
  color: #4581fb !important;
  font-weight: 600;
}

* {
  padding: 0;
  margin: 0;
}

html {
  // overflow-y: scroll;
  overflow-x: hidden;
}

:root {
  // overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;
  // overflow-y: scroll;

}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-overflow-scrolling: touch
}

.nav {
  width: 100vw;
  height: 10vw;
  position: fixed;
  background-color: #fff;
  top: 0;
  left: 0;
  display: flex;
  z-index: 99;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;

  .nav-left {
    width: 30%;
    height: 100%;

    img {
      width: 100%;
      height: 80%;
    }
  }

  .nav-right {
    width: 10%;
    height: 100%;

    img {
      width: 100%;
      height: 80%;
    }
  }
}

.zhe {
  width: 70vw;
  height: 100vh;
  position: fixed;
  background-color: #2c3e50;
  right: 0;
  top: 0;
  z-index: 100;
  transition: all 1s linear;

  a {
    text-decoration: none;
    color: #fff;
  }

  .zhe-cha {
    width: 100%;
    height: 20px;
    color: #fff;
    font-weight: 600;

    h1 {
      float: right;
      width: 5vw;
      height: 5vw;
      font-size: 8vw;
      line-height: 5vw;
   
    }
  }

  .menu-aside {
    width: 100%;
    height: 50vw;
    //  background-color: #fff;
    margin-top: 15vw;
    line-height: 13vw;
    text-align: left;

    a {
      display: block;
      width: 100%;
      padding-left: 15vw;
      // padding-top: 5vw;
      border: 1px solid #313131;
    }
  }
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
