import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 引入Animate.css动画
import 'animate.css';
//  ES6
import Axios from 'axios';
//将axios挂在到Vue的实例上
Vue.prototype.$axios = Axios 
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false



Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
